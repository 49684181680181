import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_income_budget_form',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      let v = item;
      if (v.field === 'orgCode') {
        v = {
          ...v,
          restful: '/tpm/tpmincomebudget/findAllOrgCodes',
          refresh: true,
          props: {
            remoteParams: 'orgCodeOrName',
            remote: true,
            filterable: true,
          },
          optionsKey: {
            label: 'orgName',
            value: 'orgCode',
          },
        };
      } else if (v.field === 'customerCode') {
        v = {
          ...v,
          props: {
            remoteParams: 'customerName',
            remote: true,
            filterable: true,
          },
          restful: '/tpm/tpmincomebudget/findCustomerByOrgCode',
          restfulParamsGetValue: { orgCode: 'orgCode' },
          optionsKey: {
            label: 'customerName',
            value: 'customerCode',
          },
        };
      } else if (v.field === 'terminalCode') {
        v = {
          ...v,
          props: {
            remoteParams: 'codeOrName',
            remote: true,
            filterable: true,
          },
          restful: '/tpm/tpmincomebudget/findTerminalByOrgCode',
          restfulParamsGetValue: { orgCode: 'orgCode' },
          optionsKey: {
            label: 'terminalName',
            value: 'terminalCode',
          },
        };
      } else if (v.field === 'productLevelCode') {
        v = {
          ...v,
          props: {
            remoteParams: 'productLevelCodeOrName',
            remote: true,
            filterable: true,
          },
          restful: '/tpm/tpmincomebudget/findAllProductLevel',
          optionsKey: {
            label: 'productLevelName',
            value: 'productLevelCode',
          },
        };
      } else if (v.field === 'productCode') {
        v = {
          ...v,
          restful: '/tpm/tpmincomebudget/findAllProductByLevelCode',
          restfulParamsGetValue: { productLevelCode: 'productLevelCode' },
          props: {
            remoteParams: 'productName',
            remote: true,
            filterable: true,
          },
          optionsKey: {
            label: 'productName',
            value: 'productCode',
          },
        };
      } else if (v.field === 'initAmount') {
        v = {
          ...v,
          validate: [
            {
              pattern: /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/,
              message: '请填写大于0的数字，最多两位小数',
              trigger: 'blur',
            },
          ],
        };
      }

      return v;
    },
    setHiddenFn(e) {
      this.hiddenFields((e !== 'org' && e !== 'customer' && e !== 'terminal'), 'orgCode');
      this.hiddenFields((e !== 'customer' && e !== 'terminal'), 'customerCode');
      this.hiddenFields(e !== 'terminal', 'terminalCode');
      this.hiddenFields(e !== 'product', ['productLevelCode', 'productCode']);
    },
    // 表单渲染完成后回调
    formComplete() {
      this.setHiddenFn();
      const incomeGroupCode = this.getRule('incomeGroupCode');

      incomeGroupCode.on.change = (e) => {
        this.setHiddenFn(e);
      };

      const orgCode = this.getRule('orgCode');

      orgCode.on.change = (e) => {
        this.setValue({ customerCode: '', terminalCode: '' });
        this.disabled(!e, ['customerCode', 'terminalCode']);
      };

      const productLevelCode = this.getRule('productLevelCode');

      productLevelCode.on.change = (e) => {
        this.setValue({ productCode: '' });
        this.disabled(!e, ['productCode']);
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmincomebudget/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              this.setValue(res.result);
              this.setHiddenFn(res.result.incomeGroupCode);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmincomebudget/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmincomebudget/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
